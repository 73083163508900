import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NO_SESSION_ID, TocWithSid} from "../../data/data";

interface TocState {
    tocItems: TocWithSid;
    isTocLoading: boolean;
    tocLoadingError: string;
}

const initialState: TocState = {
    tocItems: {items: [], sid: NO_SESSION_ID},
    isTocLoading: false,
    tocLoadingError: '',
};

export const tocSlice = createSlice({
    name: 'toc',
    initialState,
    reducers: {
        tocFetching(state) {
            state.isTocLoading = true;
        },
        tocFetchingSuccess(state, action: PayloadAction<TocWithSid>) {
            state.isTocLoading = false;
            state.tocLoadingError = '';
            state.tocItems = action.payload;
        },
        tocFetchingError(state, action: PayloadAction<string>) {
            state.isTocLoading = false;
            state.tocLoadingError = action.payload;
        },
    },
});


export default tocSlice.reducer;
