import {FC} from "react";
import {Button} from "antd";
import {getFullLink} from "../utils/utils";
import {fetchExrs} from "../store/reducers/AyncActions";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import CustomDivider from "./CustomDivider";
import styled from "styled-components";

const NumButtons: FC = () => {
    let items: any = [];

    const dispatch = useAppDispatch();
    const {task, selectedVar, selectedNum, settings} = useAppSelector(state => state.exrsReducer);
    const {tocItems} = useAppSelector(state => state.tocReducer);
    const {chooseNum} = exercisesSlice.actions;

    const getData = (val: number) : any => {
        if (selectedVar !== undefined) {
            let link: string = getFullLink(task!.apiLink, val, selectedVar);
            dispatch(fetchExrs(link, tocItems.sid));
        }
        dispatch(chooseNum(val));
    }

    task && task.nums.forEach((val) => {
        items.push(
            <StyledButton id={val.toString()} key={val}
                    type={selectedNum === val ? "primary" : "default"}
                    shape="circle"
                    disabled={settings.varsDisaled}
                    onClick={() => getData(val)}>
                    {val}
            </StyledButton>
        );
    })

    return (
        <div>
            <CustomDivider text={"Количество заданий"}/>
            {items}
        </div>
    )
}

const StyledButton = styled(Button)`
    margin-top: 5px;
    margin-right: 5%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500; 
    height: 40px;
    width: 40px
`;

export default NumButtons;