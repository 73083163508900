import {Menu} from 'antd';
import {findByLink, tocByGrade} from "../data/data";
import {gradeToString} from "../utils/utils";
import {FC} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import styled from "styled-components";

interface ITocProps {
    grade: number;
}

export const TOC_PREFIX = "toc_";

const { SubMenu } = Menu;

const Toc: FC<ITocProps> = (args: ITocProps) => {

    const dispatch = useAppDispatch();
    const {tocItems} = useAppSelector(state => state.tocReducer);
    const {back2Initial, chooseTask} = exercisesSlice.actions;

    let items_raw = tocByGrade(tocItems.items, args.grade);
    let menu_items: any = [];
    let keys2: any = [];

    items_raw.forEach((grade_items, ind) => {
        let sub_menu_items: any = [];
        grade_items.forEach((item) => {
            sub_menu_items.push(
                <StyledMenuItem
                    style={{lineHeight: '1.15em'}}
                    key={item.apiLink} onClick={(e) => dispatch(chooseTask(findByLink(tocItems.items, e.key)!))}>
                    {item.title}
                </StyledMenuItem>
            );
        })
        menu_items.push(
            <StyledSubMenu key={TOC_PREFIX + ind}
                           title={gradeToString(ind)}
                           onTitleClick={() => dispatch(back2Initial())}>
                {sub_menu_items}
            </StyledSubMenu>
        );
        if (args.grade > 0)
            keys2.push(TOC_PREFIX + ind);
    })

    //console.log(args.grade);
    //console.log(keys2);

    return (
        <Menu
            mode="inline"
            //defaultOpenKeys={keys2}       //работает но как-то криво
            style={{height: "100%", borderRight: 0}}
        >
            {menu_items}
        </Menu>
    );
}

const StyledSubMenu = styled(SubMenu)`
    font-size: 18px;
    font-weight: 500;
`;

const StyledMenuItem = styled(Menu.Item)`
    font-size: 16px;
    font-weight: 400; 
    height: auto;
    white-space: normal;
`;

export default Toc;

