import {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {calculateTimeLeft} from "../utils/utils";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import {Space, Typography} from "antd";
import CustomDivider from "./CustomDivider";

const { Text } = Typography;

const Timer: FC = () => {

    const dispatch = useAppDispatch();
    const {timer} = useAppSelector(state => state.exrsReducer);
    const {setTimerDiff} = exercisesSlice.actions;

    useEffect(() => {
        if (timer.isRunning)
            setTimeout(() => {
                dispatch(setTimerDiff());
            }, 1000)
    });

    return (
        <div>
            <CustomDivider text={"Время выполнения"}/>
            <Space align="center" style={{marginTop: "5px", marginLeft: "5px"}}>
                <Text style={{fontSize: "28px", fontWeight: 400}}>{timer.isRunning ? calculateTimeLeft(timer.timerDiff, true) : '0 : 00 : 00'}</Text>
            </Space>
        </div>
    );
}

export default Timer;