import axios from 'axios';
import {UserResultDto} from "../data/data";

const BASE_URL = (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '');
const TOC_URL = '/api/toc/';
const TASK_URL = '/api/task/';
const RESULT_URL = '/api/result/';

export const NUMBER_PARAM = 'num';
export const VARIANT_PARAM = 'var';

class Api {
    client

    constructor() {
        this.client = axios.create({
            baseURL: BASE_URL,
            responseType: 'json',
        })
    }

    async getToc(sessionId: string) {
        return await this.client
            .get(BASE_URL + TOC_URL + sessionId)
            .then(res => {return res.data})
    }

    async getExrs(link: string, sid: string) {
        return await this.client
            .get(BASE_URL + TASK_URL + sid + '/' + link)
            .then(res => {return res.data})
    }

    async postResults(link: string, sid: string, result: UserResultDto) {
        return await this.client
            .post(BASE_URL + RESULT_URL + sid + '/' + link, result)
    }
}

export const api = new Api();