import {Layout, Menu, Tooltip,} from 'antd';
import {getGradesList, gradeToString} from "../utils/utils";
import React, {FC, useEffect, useState} from "react";
import {TOC_NUMBERS} from "../data/data";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {fetchToc} from "../store/reducers/AyncActions";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import styled from "styled-components";
import IntroPage from "./IntroPage";
import TaskPage from "./TaskPage";

const { Header} = Layout;

const MainPage: FC = () => {
    const [selectedGrade, setSelectedGrade] = useState(TOC_NUMBERS.INTRO_TOC);

    const dispatch = useAppDispatch();
    const {back2Initial} = exercisesSlice.actions;
    const {tocItems} = useAppSelector(state => state.tocReducer);

    let loadedGrades = getGradesList(tocItems);

    const chooseGrade = (grade: number) => {
        setSelectedGrade(grade);
        dispatch(back2Initial());
    };

    useEffect(() => {
        sessionStorage.setItem("sessionId", Date.now().toString());
        let s = sessionStorage.getItem("sessionId") ? sessionStorage.getItem("sessionId") : Date.now().toString();
        dispatch(fetchToc(s!));
    }, [dispatch])

    return (
        <Layout>
            <StyledHeader>
                <Menu theme="light" mode="horizontal">
                    <StyledMenuItem key="initial" onClick={() => chooseGrade(TOC_NUMBERS.INTRO_TOC)} defaultChecked={true}>Введение</StyledMenuItem>
                    <StyledMenuItem key="toc" onClick={() => chooseGrade(TOC_NUMBERS.ALL_TOC)}>Оглавление</StyledMenuItem>
                    {
                        [...Array(TOC_NUMBERS.GRADE_10TH_TOC+1).keys()]
                            .slice(1)
                            .map((num) =>
                                    <StyledMenuItem key={num}
                                                disabled={loadedGrades[num - 1]}
                                                onClick={() => chooseGrade(num)}>
                                        {loadedGrades[num - 1] ?
                                            <Tooltip title={"Задания за " + gradeToString(num) + " скоро появятся"}>
                                                {gradeToString(num)}
                                            </Tooltip>
                                            :
                                            gradeToString(num)
                                        }
                                    </StyledMenuItem>
                                )
                    }
                </Menu>
            </StyledHeader>
            {selectedGrade === TOC_NUMBERS.INTRO_TOC ?
                <IntroPage/> :
                <TaskPage grade={selectedGrade}/>}
        </Layout>
    );
}


const StyledHeader = styled(Header)`
    padding: 0 0 0 0; 
`;

const StyledMenuItem = styled(Menu.Item)`
    font-size: 18px;
    font-weight: 500;
`;

export default MainPage;