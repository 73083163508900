import {NUMBER_PARAM, VARIANT_PARAM} from "../api/api";
import {
    ExrsData, FONT_CHAR_WIDTHS, TOC_NUMBERS, TocWithSid, UserAnswer
} from "../data/data";

const md5 = require("blueimp-md5");

export function gradeToString(num: number) : string {
    if (num === TOC_NUMBERS.INTRO_TOC)
        return 'Введение';
    else if (num === TOC_NUMBERS.ALL_TOC)
        return 'Оглавление';
    else
        return num + 'й класс';
}

export function getFullLink(task: string, num: number | undefined, variant: number | undefined) : string {
    let res: string = task;
    if (num && variant)
        res = task + '?' + NUMBER_PARAM + '=' + num + '&' + VARIANT_PARAM + '=' + variant;
    else if (num && variant === undefined)
        res = task + '?' + NUMBER_PARAM + '=' + num;
    else if (variant && num === undefined)
        res = task + '?' + VARIANT_PARAM + '=' + variant;
        return res;
}

export function isAnswerCorrect(answer: string, correctAnswer: string): boolean {
    return md5(answer).toUpperCase() === correctAnswer;
}

export function getAnswerById(id: string, arr: UserAnswer[]) : string {
    let map = new Map(arr.map((i : UserAnswer) => [i.id, i.answer]));
    let res = map.get(id);
    return res ? res : '';
}

export function calculateTimeLeft(dif: number, space: boolean) : string {
    let timeLeft = {hours: 0, minutes: 0, seconds: 0};

    if (dif > 0) {
        timeLeft = {
            hours: Math.floor(dif / (1000 * 60 * 60)),
            minutes: Math.floor((dif / 1000 / 60) % 60),
            seconds: Math.floor((dif / 1000) % 60),
        };
    }
    let delim = space ? ' : ' : ':';
    return timeLeft['hours'] + delim + `${timeLeft['minutes']}`.padStart(2, '0') + delim + `${timeLeft['seconds']}`.padStart(2, '0');
}

//ф-ция вернет необходимую величину letter-spacing чтобы длина str была равна maxWidth
export function getLetterSpacing(maxWidth: number, str: string) : number {
    let spacesNum = str.split(" ").length - 1;
    let strWidth = getStringWidth(str);
    console.log(str + ", " + maxWidth + ", " + strWidth);
    if (Math.abs(maxWidth - strWidth) < spacesNum * FONT_CHAR_WIDTHS[" "])
        return 0;
    return spacesNum / (maxWidth - strWidth);
}

//ф-ция вернет width для данной строки
export function getStringWidth(str: string) : number {
    let res = 0;
    for (const elem in FONT_CHAR_WIDTHS) {
        //count += (str.match(new RegExp(elem, "g")) || []).length;
        let count = str.split(elem).length - 1;
        res += count * FONT_CHAR_WIDTHS[elem];
    }
    return res;
}

export function getExrMaxWidth(data: ExrsData) : number {
    let maxWidth = 0;
    for (const elem of data.exercises) {
        let tt = getStringWidth(elem.task);
        if (tt > maxWidth)
            maxWidth = tt;
    }
    return maxWidth;
}
//ф-ция для получения списка классов, для которых есть задания
export function getGradesList(data: TocWithSid) : boolean[] {
    //в компоненте ставится признак disabled = {true}, поэтому изначально весь массив - true
    let ar = [true, true, true, true, true, true, true, true, true, true];
    if (data && data.items) {
        for (let ii in data.items) {
            let grade = data.items[ii].grade;
            //проверяем, что это значение число положительное и до 10 и что его еще записали
            if (grade && grade < 11 && grade > 0 && ar[grade - 1])
                ar[grade - 1] = false;
        }
    }
    return ar;
}