import React, {FC} from 'react'
import MainPage from "./components/MainPage";

const App: FC = () => {
  //console.log(window.innerHeight);
  //console.log(window.innerWidth);
  return (
      <MainPage/>
  )
}

export default App;