import {isAnswerCorrect} from "../utils/utils";

export type Variant = Map<string, string>;

export type TocData = {
    grade: number;
    title: string;
    variants: Variant;
    nums: number[];
    apiLink: string;
    params: number[];
}

export type TocWithSid = {
    items: TocData[];
    sid: string;
}

export type Exercise = {
    id: string;
    task: string;
    answer: string;
}

export type ExrsData = {
    type: string;
    exerciseDescription: string;
    ansMaxLength: number;
    exercises: Exercise[];
}

export type UserAnswer = {
    id: string;
    answer: string;
}

export type UserResult = {
    allTasksNum: number;
    correct: number;
    incorrect: number;
    unanswered: number;
    time: number;
}

export type UserResultDto = {
    result: UserResult;
    answers: {
        exercise: Exercise,
        userAnswer: string,
    }[],
}

export type ComponentsSettings = {
    buttonText: BUTTON_TEXT;
    buttonDisabled: boolean;
    numsDisabled: boolean;
    varsDisaled: boolean;
    inputsDisabled: boolean;
}

export type TimerState = {
    startTime: number;
    timerDiff: number;
    isRunning: boolean;
}

/*
export type TimerData = {
    hours: number;
    minutes: number;
    seconds: number;
}
*/

export enum BUTTON_TEXT {
    BUTTON_INITIAL_TEXT = "Решать",
    BUTTON_RUNNING_TEXT = "Проверить и завершить",
    BUTTON_AGAIN_TEXT = "Решать снова",
}

export enum TASK_STATES {
    TASK_ZERO_STATE,            //начальное состояние, только выбрали задание, но не выбрали вариант - кнопка неактивная
    TASK_INITIAL_STATE,         //выбрали вариант, можно решать, кнопка активная
    TASK_RUNNING_STATE,         //нажали на кнопку, решаем примеры
    TASK_END_STATE,             //проверяем и завершаем
}

export enum TOC_NUMBERS {
    INTRO_TOC = -1,
    ALL_TOC,
    GRADE_1TH_TOC,
    GRADE_2ND_TOC,
    GRADE_3RD_TOC,
    GRADE_4TH_TOC,
    GRADE_5TH_TOC,
    GRADE_6TH_TOC,
    GRADE_7TH_TOC,
    GRADE_8TH_TOC,
    GRADE_9TH_TOC,
    GRADE_10TH_TOC,
}

export const NO_SESSION_ID = 'noSessionId';

//TO DO цифра взята из замеров для шрифта
// fontSize: "16px",
// fontWeight: 500,
// fontFamily: "Arial, sans-serif", для всех символов кроме пробела минуса, делить и скобок, запятой и точки
export const FONT_SIZE_16_CHAR_WIDTH_BIG = 9;

export const FONT_CHAR_WIDTHS : { [key: string]: number } = {
    "-": 5.3,
    "+": 9.3,
    " ": 5,
    "1": 9,
    "2": 9,
    "3": 9,
    "4": 9,
    "5": 9,
    "6": 9,
    "7": 9,
    "8": 9,
    "9": 9,
    "0": 9,
    "\u00b7": 4,
    "x": 8,
    ":": 4.4,
    "(": 5.3,
    ")": 5.3,
    "=": 9.34,
    ",": 4.45,
    ".": 4.45,
};

export function findByLink(data: TocData[], link: string): TocData | undefined {
    let item: any = undefined;
    data.forEach(d => {
        if (d.apiLink === link) {
            item = d;
        }
    })
    return item;
}

export function tocByGrade(data: TocData[], grade: number): TocData[][] {
    let items_raw = data && data
        .filter(item => (grade > 0 ? item.grade === grade : true))
        .sort((a, b) => (a.grade - b.grade))
        .sort((a, b) => a.title.localeCompare(b.title));

    let raw_arr: any[] = [];

    items_raw && items_raw.forEach((item) => {
        if (!raw_arr[item.grade]) {
            raw_arr[item.grade] = [];
        }
        raw_arr[item.grade].push(item);
    });

    return raw_arr;
}

export function fillUserAnswerDto(result: UserResult, exrs: ExrsData, answers: UserAnswer[]): UserResultDto {
    let res: UserResultDto = {
        result: result,
        answers: [],
    };
    if (exrs && exrs.exercises.length > 0) {
        exrs.exercises.forEach(e =>
            res.answers.push({exercise: e, userAnswer: findAnswer(answers, e.id)}))
    }
    return res;
}

const findAnswer = (answers: UserAnswer[], id: string) : string => {
    if (answers && answers.length > 0) {
        for (let ii of answers) {
            if (ii.id === id)
                return ii.answer;
        }
    }
    return '';
}

export const getResults = (exrs: ExrsData, answers: UserAnswer[], timeDiff: number) : UserResult => {
    let tasksNum = exrs.exercises.length;
    let correct :number, incorrect : number, unanswered :number;
    correct = incorrect = unanswered = 0;
    let map1 = new Map(exrs.exercises.map((y: Exercise) => [y.id, y.answer]));
    let map2 = new Map(answers.map((i : UserAnswer) => [i.id, i.answer]));
    map1.forEach((answer, id) => {
        let r = map2.get(id);
        if (r === undefined) unanswered += 1;
        else if (isAnswerCorrect(r, answer)) correct += 1;
        else incorrect += 1;
    })
    return {
        allTasksNum: tasksNum,
        correct: correct,
        incorrect: incorrect,
        unanswered: unanswered,
        time: timeDiff,
    }
}

