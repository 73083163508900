import {AppDispatch} from "../store";
import {api} from "../../api/api";
import {tocSlice} from "./TocSlice";
import {exercisesSlice} from "./ExerciseSlice";
import {UserResultDto} from "../../data/data";

export const fetchToc = (sessionId: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(tocSlice.actions.tocFetching());
        const response = await api.getToc(sessionId);
        dispatch(tocSlice.actions.tocFetchingSuccess(response));
    } catch (e : any) {
        dispatch(tocSlice.actions.tocFetchingError(e.message));
    }
};

export const fetchExrs = (link: string, sid: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(exercisesSlice.actions.exrsFetching());
        const response = await api.getExrs(link, sid);
        dispatch(exercisesSlice.actions.exrsFetchingSuccess(response));
    } catch (e : any) {
        dispatch(exercisesSlice.actions.exrsFetchingError(e.message));
    }
};

export const senResults = (link: string, sid: string, result: UserResultDto) => async (dispatch: AppDispatch) => {
    await api.postResults(link, sid, result);
};