import React, {FC} from "react";
import Toc from "./Toc";
import {Breadcrumb, Layout} from "antd";
import {gradeToString} from "../utils/utils";
import TaskTable from "./TaskTable";
import Variants from "./Variants";
import NumButtons from "./NumButtons";
import Timer from "./Timer";
import TaskResult from "./TaskResult";
import ControlButton from "./ControlButton";
import Sider from "antd/es/layout/Sider";
import styled from "styled-components";
import {useAppSelector} from "../hooks/redux";
import {findByLink} from "../data/data";

interface ITaskPageProps {
    grade: number;
}

const TaskPage: FC<ITaskPageProps> = (args: ITaskPageProps) => {

    const {tocItems, isTocLoading, tocLoadingError} = useAppSelector(state => state.tocReducer);
    const {task} = useAppSelector(state => state.exrsReducer);

    return (
        <Layout>
            <Sider width="24%">
                {isTocLoading && <h3 style={{background: '#c5ab29'}}>Идет загрузка оглавления</h3>}
                {tocLoadingError && <h3 style={{background: '#c5ab29'}}>Оглавление не загружено. Ошибка: {tocLoadingError}</h3>}
                {tocItems && <Toc grade={args.grade}/>}
            </Sider>
            <Layout style={{padding: '0 24px 24px'}}>
                {/* @ts-ignore */}
                <Breadcrumb style={{margin: '16px 0'}}>
                    {/* @ts-ignore */}
                    <StyledBreadcrumbItem>{task && gradeToString(findByLink(tocItems.items, task.apiLink)?.grade)}</StyledBreadcrumbItem>
                    {/* @ts-ignore */}
                    <StyledBreadcrumbItem>{task && task.title}</StyledBreadcrumbItem>
                </Breadcrumb>
                <TaskTable/>
            </Layout>
            <StyledRightSider width='18%'>
                <StyledDiv>
                    <Variants/>
                    <NumButtons/>
                    <Timer/>
                    <TaskResult/>
                    <ControlButton/>
                </StyledDiv>
            </StyledRightSider>
        </Layout>
    )
}

export default TaskPage;

const StyledRightSider = styled(Sider)`
    background: wheat;
`;

const StyledDiv = styled.div`
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
`;

const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
    font-size: 16px;
    font-weight: 400;
`;