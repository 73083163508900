import {
    BUTTON_TEXT,
    ComponentsSettings,
    ExrsData,
    TASK_STATES,
    TimerState,
    TocData,
    UserAnswer, UserResult
} from "../../data/data";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ExerciseState {
    exrs: ExrsData | undefined;                 //примеры, получаемых с бека для выбранного задания
    task: TocData | undefined;                  //выбранное задание
    isExrsLoading: boolean;                     //признак загрузки примеров
    exrsLoadingError: string;                   //ошибка при загрузке примеров
    selectedVar: number | undefined;            //выбранный вариант в задании
    selectedNum: number | undefined;            //выбранное кол-во примеров в задании
    exrsState: TASK_STATES;                     //текущее состояние
    userAnswers: UserAnswer[];                  //ответы пользователей
    taskResult: UserResult;                      //строка результатов
    timer: TimerState;                          //состояние таймера
    settings: ComponentsSettings;               //объект где храняться все настройки компонентов, которые меняются в зависимости от состояния
}

const zeroResult : UserResult = {
    allTasksNum: 0, correct: 0, incorrect: 0, unanswered: 0, time: 0
}

const zeroTimer : TimerState = {
    startTime: 0, timerDiff: 0, isRunning: false
};

//настройки для состояния TASK_ZERO_STATE
const zeroStateSettings = {
    buttonText: BUTTON_TEXT.BUTTON_INITIAL_TEXT,
    buttonDisabled: true,
    numsDisabled: false,
    varsDisaled: false,
    inputsDisabled: true,
};

//настройки для состояния TASK_INITIAL_STATE
const initialStateSettings = {
    ...zeroStateSettings,
    buttonDisabled: false,
};

//настройки для состояния TASK_RUNNING_STATE
const runningStateSettings = {
    buttonText: BUTTON_TEXT.BUTTON_RUNNING_TEXT,
    buttonDisabled: false,
    numsDisabled: true,
    varsDisaled: true,
    inputsDisabled: false,
};

//настройки для состояния TASK_END_STATE
const endStateSettings = {
    ...zeroStateSettings,
    buttonText: BUTTON_TEXT.BUTTON_AGAIN_TEXT,
    buttonDisabled: false,
};

const initialState: ExerciseState = {
    exrs: undefined,
    task: undefined,
    isExrsLoading: false,
    exrsLoadingError: '',
    selectedVar: undefined,
    selectedNum: undefined,
    exrsState: TASK_STATES.TASK_ZERO_STATE,
    userAnswers: [],
    taskResult: zeroResult,
    timer: zeroTimer,
    settings: zeroStateSettings,
};

export const exercisesSlice = createSlice({
    name: 'exrs',
    initialState,
    reducers: {
        exrsFetching(state) {
            state.isExrsLoading = true;
        },
        exrsFetchingSuccess(state, action: PayloadAction<ExrsData>) {
            state.isExrsLoading = false;
            state.exrs = action.payload;
            state.exrsLoadingError = '';
        },
        exrsFetchingError(state, action: PayloadAction<string>) {
            state.isExrsLoading = false;
            state.exrsLoadingError = action.payload;
        },
        back2Initial(state) {
            state.exrs = undefined;
            state.exrsState = TASK_STATES.TASK_ZERO_STATE;
            state.task = undefined;
            state.settings = zeroStateSettings;
            state.userAnswers = [];                                         //обнуляем ответы
            state.timer = zeroTimer;
            state.taskResult = zeroResult;
        },
        chooseTask(state, action: PayloadAction<TocData>) {
            state.task = action.payload;
            state.exrs = undefined;                         //обнуляем все задания при выборе задания
            state.selectedNum = state.task.nums[0];         //инициализируем кол-во заданий минимальным из возможных
            state.selectedVar = undefined;                  //обнуляем выбранный вариант
            state.exrsState = TASK_STATES.TASK_ZERO_STATE;
            state.settings = zeroStateSettings;
            state.userAnswers = [];                                         //обнуляем ответы
            state.taskResult = zeroResult;
        },
        chooseNum(state, action: PayloadAction<number>) {
            state.selectedNum = action.payload;
            state.userAnswers = [];                                         //обнуляем ответы
            state.taskResult = zeroResult;
        },
        chooseVariant(state, action: PayloadAction<number>) {
            state.selectedVar = action.payload;
            state.exrsState = TASK_STATES.TASK_INITIAL_STATE;   //если выбран вариант, то меняем состояние, активируем кнопку
            state.settings = initialStateSettings;
            state.userAnswers = [];                                         //обнуляем ответы
            state.taskResult = zeroResult;
        },
        //изменение состояния (конечный автомат) реализуем в этой ф-ции, а не по всему коду
        changeState(state) {
            if (state.exrsState === TASK_STATES.TASK_ZERO_STATE) {
                state.exrsState = TASK_STATES.TASK_INITIAL_STATE;
                state.settings = initialStateSettings;
            }
            else if (state.exrsState === TASK_STATES.TASK_INITIAL_STATE) {
                state.exrsState = TASK_STATES.TASK_RUNNING_STATE;
                state.settings = runningStateSettings;

                //стартуем таймер
                state.timer.isRunning = true;
                state.timer.startTime = +new Date();
            }
            else if (state.exrsState === TASK_STATES.TASK_RUNNING_STATE) {
                state.exrsState = TASK_STATES.TASK_END_STATE;
                state.settings = endStateSettings;

                //стопаем таймер
                state.timer.isRunning = false;
                state.timer.startTime = 0;
            }
            else if (state.exrsState === TASK_STATES.TASK_END_STATE) {
                state.exrsState = TASK_STATES.TASK_RUNNING_STATE;
                state.settings = runningStateSettings;
                state.userAnswers = [];                                         //обнуляем ответы
                state.taskResult = zeroResult;

                //стартуем таймер
                state.timer.isRunning = true;
                state.timer.startTime = +new Date();
            }
        },
        addUserAnswer(state, action: PayloadAction<UserAnswer>) {
            let map = new Map(state.userAnswers.map((i : UserAnswer) => [i.id, i.answer]));
            map.set(action.payload.id, action.payload.answer);
            let arr: UserAnswer[] = [];
            map.forEach((answer, id) => {
                arr.push({id, answer} as UserAnswer);
            });
            state.userAnswers = arr;
        },
        setTimerDiff(state) {
            if(state.timer.isRunning)
                state.timer.timerDiff = +new Date() - state.timer.startTime;
            else state.timer.timerDiff = 0;
        },
        saveResults(state, action: PayloadAction<UserResult>) {
            state.taskResult = action.payload;
        }
    }
});

export default exercisesSlice.reducer;

