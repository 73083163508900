import React, {FC} from "react";
import {Col, Row} from "antd";
import Expression from "../task_types/Expression";
import {useAppSelector} from "../hooks/redux";
import Title from "antd/es/typography/Title";
import {getExrMaxWidth} from "../utils/utils";

const TaskTable: FC = () => {
    let items1 : any = [];
    let items2 : any = [];

    const exrsPerCol = 10;

    const {exrs, settings} = useAppSelector(state => state.exrsReducer);

    if (exrs) {
        let exrMaxLength = getExrMaxWidth(exrs);

        exrs.exercises.forEach((item, index) => {
            if (index % exrsPerCol === 0)
                items1 = [];
            if (exrs.type === "numEq" || exrs.type === "equation") {
                items1.push(
                    <Expression key={index}
                                data={item}
                                disabled={settings.inputsDisabled}
                                ansMaxLength={exrs.ansMaxLength}
                                exrMaxWidth={exrMaxLength}/>
                );
            }
            let tt = (index / exrsPerCol >= 2) ? "3vh" : "0";
            items2[Math.floor(index / exrsPerCol)] = (
                <Col span={12} key={'col_'+index} style={{marginTop: tt}}>
                    {items1}
                </Col>
            )
        })

    }

    return (
        <div>
            {/*<Image src='/3e3ef96629a4f8663498923f92a90ca5.png'/>*/}
            <Row>
                <Title level={4}>{exrs && exrs.exerciseDescription}</Title>
            </Row>
            <Row justify={"start"}>
                {items2}
            </Row>
        </div>
    );
}

export default TaskTable;