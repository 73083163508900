import {FC} from "react";
import {Button, Row} from "antd";
import {getFullLink} from "../utils/utils";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {fetchExrs} from "../store/reducers/AyncActions";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import CustomDivider from "./CustomDivider";
import styled from "styled-components";

const Variants: FC = () => {
    const dispatch = useAppDispatch();
    const {task, selectedNum, selectedVar, settings} = useAppSelector(state => state.exrsReducer);
    const {tocItems} = useAppSelector(state => state.tocReducer);
    const {chooseVariant} = exercisesSlice.actions;

    let items: any = [];

    const getData = (ind: number) : any => {
        let link: string = getFullLink(task!.apiLink, selectedNum, ind);
        dispatch(chooseVariant(ind));
        dispatch(fetchExrs(link, tocItems.sid));
    }

    if (task && task.variants) {
        for (const [ind, val] of Object.entries(task.variants)) {
            items.push(
                <Row key={'row_' + val}>
                    <StyledButton key={val} id={ind}
                            type={selectedVar === parseInt(ind, 10) ? "primary" : "default"}
                            shape="round"
                            disabled={settings.numsDisabled}
                            onClick={() => getData(parseInt(ind, 10))}>
                        {val}
                    </StyledButton>
                </Row>
            );
        }
    }
    return (
        <div>
            <CustomDivider text={"Варианты задания"}/>
            {items}
        </div>
    );
}

const StyledButton = styled(Button)`
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400; 
    height: auto;
    max-width: 300px;
    white-space: normal;
`;

export default Variants;