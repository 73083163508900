import {combineReducers} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import tocReducer from './reducers/TocSlice';
import exrsReducer from './reducers/ExerciseSlice';

const rootReducer = combineReducers({
    tocReducer,
    exrsReducer,
});


export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        //middleware: (getDefaultMiddleware) =>
        //    getDefaultMiddleware()
        //        .concat(postAPI.middleware)
    })
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
