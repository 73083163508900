import {FC} from "react";
import {Layout, List} from "antd";
import styled from "styled-components";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";


const IntroPage: FC = () => {
    return (
        <Layout>
            <div style={{minWidth: "700px", maxWidth: "50%", margin: "0 auto", marginBottom: "10%"}}>
                <StyledDiv>
                    <Title style={{fontSize: 46, fontWeight: 600}}>Математический тренажер</Title>
                </StyledDiv>
                <StyledDiv>
                    <List>
                        <StyledListItem>
                            <StyledAref href={"#whatis"}>Что это?</StyledAref>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledAref href={"#why"}>Зачем?</StyledAref>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledAref href={"#how"}>Как пользоваться?</StyledAref>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledAref href={"#contacts"}>Контакты</StyledAref>
                        </StyledListItem>
                    </List>
                </StyledDiv>
                <StyledArticle>
                    <StyledH1 id={"whatis"}>Что это?</StyledH1>
                    <StyledP>Математический тренажер для развития навыков устного счета и решения примеров из школьной программы. </StyledP>
                    <StyledP>Все примеры в заданиях формируются абсолютно случайным образом.
                            Разделение заданий по классам (особенно для начальной школы) проведено условно,
                            согласно моего понимания соответствия сложности задания определенному классу.
                            Все предложения по именованию заданий и их группировке можно писать на почту:<br/>
                            <Text copyable={true} style={{color: "#1890ff", fontWeight: 600, fontSize: "1rem"}}>matran832@gmail.com</Text></StyledP>
                </StyledArticle>

                <StyledArticle>
                    <StyledH1 id={"why"}>Зачем?</StyledH1>
                    <StyledP>Мысль о том, что надо что-то делать возникла, когда ребенок 75 на 5 начал делить столбиком, а не дал сразу ответ.
                            Столбиком решать может и не плохо, но медленно, и поэтому не все примеры из контрольной получается за урок решить.</StyledP>
                    <StyledP>На существующих учебных платформах хорошо и подробно разбираются темы,
                            но именно примеров для закрепления материала и оттачивания навыков, не так много.
                            Конечно же, есть большое количество печатных вариантов различных пособий.
                            Но они на один раз, а дальше надо снова либо покупать, либо скачивать и распечатывать - тратить время.
                            А самое главное - результат надо проверить. И если примеры вида "2 + 2 - 3 + 7 = " еще относительно быстро можно в уме посчитать,
                            то на более сложные нужно больше времени.</StyledP>
                    <StyledP>По вышеперечисленным причинам и родилась идея прикрутить web-интерфейс к функциям генерации заданий.
                            Чтобы можно было создать необходимое количество заданий и тут же проверить их решение. Плюс посчитать время выполнения задания.
                            Никакого контроля за тем, что ребенок решал сам, а не, например, на калькуляторе не ведется.
                            Функция контроля выходит за рамки данного приложения.</StyledP>
                    <StyledP>Все варианты представленных заданий создавались по подобию заданий когда-то увиденных в учебниках или пособиях.
                            Приложение совсем не "mobile first", в первую очередь создавалось под работу на компьютере,
                            можно пробовать заходить с планшетов (особенно с большим экраном), с мобильников - работает, но отображается все плохо.</StyledP>
                    <StyledP>Буду рад, если данное приложение кому-то так же окажется полезным.
                            Как было сказано выше, все предложения по работе приложения можно писать сюда:<br/>
                            <Text copyable={true} style={{color: "#1890ff", fontWeight: 600, fontSize: "1rem"}}>matran832@gmail.com</Text>
                        </StyledP>
                </StyledArticle>

                <StyledArticle>
                    <StyledH1 id={"how"}>Как пользоваться?</StyledH1>
                    <StyledP>1. В верхнем меню выбираем раздел с нужным классом.</StyledP>
                    <StyledP>2. В меню слева появятся все задания для данного класса. В разделе "Оглавление" представлены все задания по всем классам.</StyledP>
                    <StyledP>3. После выбора нужного задания в правом меню будут представлены варианты данного задания (или только один вариант).
                            А так же количество примеров в задании.</StyledP>
                    <StyledP>4. Выбираем нужный вариант задания, выбираем количество примеров, которое будет в задании.
                            В центральной части отображаются примеры для выбранного варианта.</StyledP>
                    <StyledP>5. После выбора варианта задания и количества примеров становится активной кнопка "Решать".
                        По нажатию на эту кнопку запустится таймер потраченного на задание времени и станут доступными для редактирования поля ввода ответа.</StyledP>
                    <StyledP>6. Решаем примеры, ответ записываем в поле ввода.</StyledP>
                    <StyledP>7. В любой момент можно нажать кнопку "Проверить и завершить". Будут проверены ответы и выведен результат выполнения задания. </StyledP>
                    <StyledP>8. По завершению можно нажать кнопку "Решать снова" или перейти к другому варианту/заданию.</StyledP>
                </StyledArticle>

                <StyledArticle>
                    <StyledH1 id={"contacts"}>Контакты</StyledH1>
                    <StyledP>По всем предложениям, замечаниям, пожеланиям, найденным ошибкам можно писать на почту:<br/>
                        <Text copyable={true} style={{color: "#1890ff", fontWeight: 600, fontSize: "1rem"}}>matran832@gmail.com</Text>
                    </StyledP>
                </StyledArticle>
            </div>
        </Layout>
    )
}

export default IntroPage;

const StyledDiv = styled.div`
    margin-top: 5%;
`;

const StyledListItem = styled(List.Item)`
    padding: 6px 0;
`;

const StyledAref = styled.a`
    font-size: 20px;
    font-weight: 500
`;

const StyledArticle = styled.article`
    margin-top: 5%;
`;

const StyledH1 = styled.h1`
    font-size: 24px;
    font-weight: 500;
`;

const StyledP = styled.p`
    font-size: 1rem;
    line-height: 1.56;
`;