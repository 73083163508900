import {FC} from "react";
import {fillUserAnswerDto, getResults, TASK_STATES, UserResult} from "../data/data";
import {Button} from "antd";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import {getFullLink} from "../utils/utils";
import {fetchExrs, senResults} from "../store/reducers/AyncActions";
import styled from "styled-components";


const ControlButton: FC = () => {
    const dispatch = useAppDispatch();
    const {task, exrs, userAnswers, timer, exrsState, selectedVar, selectedNum, settings} = useAppSelector(state => state.exrsReducer);
    const {tocItems} = useAppSelector(state => state.tocReducer);
    const {changeState, saveResults} = exercisesSlice.actions;

    //const handleClick = (e: any): any => {
    //    args.processClick(e.currentTarget.getAttribute('id'));
    //}

    const handleClick = () => {
        //выполняли задание, нажата кнопка проверить и завершить
        if (exrsState === TASK_STATES.TASK_RUNNING_STATE) {
            let res: UserResult = getResults(exrs!, userAnswers, timer.timerDiff);
            dispatch(saveResults(res));
            let link: string = getFullLink(task!.apiLink, selectedNum, selectedVar);
            dispatch(senResults(link, tocItems.sid, fillUserAnswerDto(res, exrs!, userAnswers)))
        }
        //нажата кнопка Решать заново
        if (exrsState === TASK_STATES.TASK_END_STATE) {
            let link: string = getFullLink(task!.apiLink, selectedNum, selectedVar);
            dispatch(fetchExrs(link, tocItems.sid));
        }
        dispatch(changeState())
    }

    return (
        <StyledDiv>
            <StyledButton type="primary"
                    disabled={settings.buttonDisabled}
                    onClick={() => handleClick()}>
                        {settings.buttonText}
            </StyledButton>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10%;
`;

const StyledButton = styled(Button)`
    font-size: 20px;
    line-height: 28px;
    font-weight: 600; 
    height: auto;
    min-height: 66px;
    width: 207px;
    white-space: normal;
`;


export default ControlButton;