import React, {FC} from "react";
import {useAppSelector} from "../hooks/redux";
import {Col, Divider, Row, Space, Typography} from "antd";
import Title from "antd/es/typography/Title";
import {calculateTimeLeft} from "../utils/utils";
import CustomDivider from "./CustomDivider";
import styled from "styled-components";

const { Text } = Typography;

const TaskResult: FC = () => {
    const span1 = 16;
    const span2 = 24 - span1;

    const {taskResult} = useAppSelector(state => state.exrsReducer);

    let results;

    if (taskResult.allTasksNum !== 0) {
        results = (<div>
            <Row>
                <Col span={span1}><StyledText>Время</StyledText></Col>
                <Col span={span2}><StyledText>{calculateTimeLeft(taskResult.time, false)}</StyledText></Col>
            </Row>
            <Row>
                <Col span={span1}><StyledText style={{color: "green"}}>верно</StyledText></Col>
                <Col span={span2}><StyledText style={{color: "green"}}>{taskResult.correct}</StyledText></Col>
            </Row>
            <Row>
                <Col span={span1}><StyledText style={{color: "red"}}>неверно</StyledText></Col>
                <Col span={span2}><StyledText style={{color: "red"}}>{taskResult.incorrect}</StyledText></Col>
            </Row>
            {taskResult.unanswered > 0 &&
            <Row>
                <Col span={span1}><StyledText style={{color: "grey"}}>не решено</StyledText></Col>
                <Col span={span2}><StyledText style={{color: "grey"}}>{taskResult.unanswered}</StyledText></Col>
            </Row>}
            <Row>
                <Col span={span1}><StyledText>всего</StyledText></Col>
                <Col span={span2}><StyledText>{taskResult.allTasksNum}</StyledText></Col>
            </Row>
        </div>);
    } else {
        results = (
        <StyledDiv>
            <Text>{''}</Text>
        </StyledDiv>
        )
    }

    return (
        <div>
            <CustomDivider text={"Результат"}/>
            {results}
        </div>
    );

}

const StyledText = styled(Text)`
    font-size: 18px;
    font-weight: 500; 
`;

const StyledDiv = styled.div`
    min-height: 141px;
`;

export default TaskResult;