import {FC} from "react";
import {Divider} from "antd";
import Title from "antd/es/typography/Title";

interface ICustomDividerProps {
    text?: string;
}

const CustomDivider: FC<ICustomDividerProps> = (args: ICustomDividerProps) => {
    return (
        <div>
            {args.text ? <Divider orientation={"left"} style={{borderTop: '2px', marginBottom: '0px', marginTop: '8%'}}>
                <Title level={4}>{args.text}</Title>
            </Divider>
            : <Divider style={{borderTop: '2px', marginBottom: '0px'}}/>}
        </div>
    );
}

export default CustomDivider;