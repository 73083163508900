import {FC} from "react";
import {Exercise, FONT_SIZE_16_CHAR_WIDTH_BIG, TASK_STATES, UserAnswer} from "../data/data";
import {Col, InputNumber, Row} from "antd";
import {getAnswerById, isAnswerCorrect} from "../utils/utils";
import {exercisesSlice} from "../store/reducers/ExerciseSlice";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import styled from "styled-components";

interface IExpressionProps {
    disabled: boolean
    data: Exercise;
    ansMaxLength: number;
    exrMaxWidth: number;
}

const Expression: FC<IExpressionProps> = (args: IExpressionProps) => {

    const dispatch = useAppDispatch();
    const {addUserAnswer} = exercisesSlice.actions;
    const {userAnswers, exrsState} = useAppSelector(state => state.exrsReducer);

    const additionalDigits = 3;                         //кол-во дополнительных символов к длине правильного ответа

    //TO DO, рефакторинг - проверяем, что args не null ниже, а используем тут
    let input = getAnswerById(args.data.id, userAnswers);       //ответы храним в состоянии, чтоб их потом обнулять

    let backgroundColor = 'white';
    if (input !== '' && exrsState === TASK_STATES.TASK_END_STATE)
        backgroundColor = isAnswerCorrect(input, args.data.answer) ? 'lightgreen' : 'rgba(238,59,59,0.50)';


    let res = <div/>;
    if (args && args.data) {
        let trans = args.disabled ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.85)";
        let answerWidth = (20 + FONT_SIZE_16_CHAR_WIDTH_BIG * (args.ansMaxLength + additionalDigits)) + "px";
        res =
        <Row justify={"start"} style={{marginBottom: "0.5vh"}}>
            <Col>
                <StyledDiv width={args.exrMaxWidth + "px"}>
                    {args.data.task}
                </StyledDiv>
            </Col>
            <Col>
                <StyledInputNumber stringMode={true}
                            background={backgroundColor}
                            width={answerWidth}
                            color={trans}
                            id={args.data.id}
                            key={args.data.id}
                            size="small"
                            controls={false}
                            disabled={args.disabled}
                            onChange={(e) => dispatch(addUserAnswer({id: args.data.id, answer: e} as UserAnswer))}
                            value={input}
                            decimalSeparator={","}
                            maxLength={args.ansMaxLength + additionalDigits}/>
            </Col>
        </Row>
    }

    return (
        <div>{res}</div>
    );
}

export default Expression;

const StyledDiv = styled.div<{width: string}>`
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
    width: ${props => props.width};
    min-width: 60px;
    text-align-last: justify;
    margin-right: 5px;
`;


const StyledInputNumber = styled(InputNumber)<{background: string, width: string, color: string}>`
    background: ${props => props.background};
    width: ${props => props.width};
    color: ${props => props.color};
    min-width: "60px";
    font-size: 16px;
    font-weight: 500;
    font-family: Arial, sans-serif;
`;
